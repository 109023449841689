import React, { Fragment, useEffect, useRef, useState } from "react";
import lodash from "lodash";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";

import { HotTable, HotColumn } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import { registerRenderer, textRenderer } from "handsontable/renderers";
import { data } from "./constants";

import PageTitle from "../../layouts/PageTitle";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import api from "../../../services/api";
import { getMonthTranslated, hasAccess } from "../../../services/utils";
import { updateSummaryCount } from "../../../services/summaryService";

import "handsontable/dist/handsontable.min.css";

registerAllModules();

const Scale = () => {
    const hotTableComponent = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carregando Registros...");
    const [scheduleItems, setScheduleItems] = useState([]);
    const [newScheduleItems, setNewScheduleItems] = useState([]);
    const [schedulesOriginalInfo, setSchedulesOriginalInfo] = useState([]);

    const [startDateFilter, setStartDateFilter] = useState(dayjs().startOf("week").toDate());
    const [endDateFilter, setEndDateFilter] = useState(dayjs().endOf("week").toDate());
    const [periodFilter, setPeriodFilter] = useState("");

    const [doctorsList, setDoctorsList] = useState([]);
    let availabilityList = [];

    const [doctorsSummary, setDoctorsSummary] = useState([]);

    useEffect(() => {
        setLoadingMessage("Carregando Registros...");
        setLoading(true);

        getDoctors();
        getSummary();
        getAvailabilty();
        getSchedule();

        setLoading(false);
    }, [])

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true")
            .then(response => {
                if (response.status === 200) {
                    setDoctorsList(response.data.doctors);
                }
            })
    }

    async function getSchedule(startDate = startDateFilter, endDate = endDateFilter, doctorId) {
        let filterDescription = `${dayjs(startDate).get("D").toString()} de ${getMonthTranslated(dayjs(startDate).get("M") + 1, "short")}. - ${dayjs(endDate).get("D").toString()} de ${getMonthTranslated(dayjs(endDate).get("M") + 1, "short")}. de ${dayjs(startDate).format("YYYY")}`;
        setPeriodFilter(filterDescription);

        await api.get(`/schedules?offset=1&limit=100&status=sch&orderBy=hospital&startDate=${dayjs(startDate).toISOString()}&endDate=${dayjs(endDate).toISOString()}`)
            // await api.get(`/schedules?offset=1&limit=100&status=sch&sortType=desc`)
            // await api.get(`/schedules?offset=1&limit=100`)
            .then(response => {
                if (response.status === 200) {
                    const scheduleItemsResponse = response.data.schedules;
                    const scheduleItemsSorted = scheduleItemsResponse.sort((a, b) => dayjs(a.schedule_date).format("YYYYMMDD")+a.hospital_short_name > dayjs(b.schedule_date).format("YYYYMMDD")+b.hospital_short_name ? 1 : -1);

                    const scheduleItemsReturned = scheduleItemsSorted.map(scheduleItem => {
                        const has_assistant = scheduleItem.scale_assistant ?? false;
                        const has_resident = scheduleItem.scale_resident ?? false;

                        return {
                            ...scheduleItem,
                            scale_assistant: has_assistant,
                            scale_resident: has_resident
                        }
                    });

                    setScheduleItems(scheduleItemsReturned);

                    let dayToControl = "";
                    let newScheduleItemsReturned = [];
                    for (let indexSchedule = 0; indexSchedule < scheduleItemsReturned.length; indexSchedule++) {
                        if (indexSchedule === 0) {
                            dayToControl = dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD");
                        }

                        if (dayToControl !== dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD")) {
                            newScheduleItemsReturned.push([
                                true,
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                ""
                            ]);

                            dayToControl = dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD");
                        }

                        let doctorsObservations = {
                            doctor1: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c514" ? "* " : ""),
                            doctor2: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c51c" ? "* " : ""),
                            doctor3: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c523" ? "* " : ""),
                            doctor4: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c52a" ? "* " : ""),
                            doctor5: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c532" ? "* " : ""),
                            doctor6: (scheduleItemsReturned[indexSchedule].forward_doctor_id === "6568e21b524e21a7b766c53a" ? "* " : "")
                        };

                        const doctorUnavailabity = availabilityList.filter(x => dayjs(scheduleItemsReturned[indexSchedule].schedule_date).isAfter(x.date_from) && dayjs(scheduleItemsReturned[indexSchedule].schedule_date).isBefore(x.date_to));
                        if (doctorUnavailabity) {
                            for (let indexUnavailability = 0; indexUnavailability < doctorUnavailabity.length; indexUnavailability++) {
                                if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c514") {
                                    doctorsObservations.doctor1 += " OUT";
                                } else if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c51c") {
                                    doctorsObservations.doctor2 += " OUT";
                                } else if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c523") {
                                    doctorsObservations.doctor3 += " OUT";
                                } else if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c52a") {
                                    doctorsObservations.doctor4 += " OUT";
                                } else if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c532") {
                                    doctorsObservations.doctor5 += " OUT";
                                } else if (doctorUnavailabity[indexUnavailability].doctor_id === "6568e21b524e21a7b766c53a") {
                                    doctorsObservations.doctor6 += " OUT";
                                }
                            }
                        }

                        const doctorsListReturned = lodash.cloneDeep(scheduleItemsReturned[indexSchedule].doctors);

                        if (doctorsListReturned.length > 0) {
                            for (let indexDoctors = 0; indexDoctors < doctorsListReturned.length; indexDoctors++) {
                                if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c514") {
                                    doctorsObservations.doctor1 += doctorsListReturned[indexDoctors].observation ?? "";
                                } else if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c51c") {
                                    doctorsObservations.doctor2 += doctorsListReturned[indexDoctors].observation ?? "";
                                } else if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c523") {
                                    doctorsObservations.doctor3 += doctorsListReturned[indexDoctors].observation ?? "";
                                } else if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c52a") {
                                    doctorsObservations.doctor4 += doctorsListReturned[indexDoctors].observation ?? "";
                                } else if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c532") {
                                    doctorsObservations.doctor5 += doctorsListReturned[indexDoctors].observation ?? "";
                                } else if (doctorsListReturned[indexDoctors].doctor_id === "6568e21b524e21a7b766c53a") {
                                    doctorsObservations.doctor6 += doctorsListReturned[indexDoctors].observation ?? "";
                                }
                            }
                        }

                        newScheduleItemsReturned.push([
                            true,
                            dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("DD/MM/YYYY"),
                            scheduleItemsReturned[indexSchedule].pacient_name,
                            scheduleItemsReturned[indexSchedule].health_plan_short_name ? scheduleItemsReturned[indexSchedule].health_plan_short_name : scheduleItemsReturned[indexSchedule].health_plan_name.substring(0, 10),
                            scheduleItemsReturned[indexSchedule].hospital_short_name ? scheduleItemsReturned[indexSchedule].hospital_short_name : scheduleItemsReturned[indexSchedule].hospital_name.substring(0, 10),
                            scheduleItemsReturned[indexSchedule].main_doctor_short_name ? scheduleItemsReturned[indexSchedule].main_doctor_short_name : scheduleItemsReturned[indexSchedule].main_doctor_name.substring(0, 10),
                            scheduleItemsReturned[indexSchedule].diagnosis.substring(0, 10),
                            scheduleItemsReturned[indexSchedule].supplier_short_name ? scheduleItemsReturned[indexSchedule].supplier_short_name : scheduleItemsReturned[indexSchedule].supplier_name.substring(0, 10),
                            doctorsObservations.doctor1,
                            doctorsObservations.doctor2,
                            doctorsObservations.doctor3,
                            doctorsObservations.doctor4,
                            doctorsObservations.doctor5,
                            doctorsObservations.doctor6,
                            "",
                            "",
                            scheduleItemsReturned[indexSchedule].scale_observation ?? ""
                        ])
                    }

                    setNewScheduleItems(newScheduleItemsReturned);

                    const originalInfoCopy = lodash.cloneDeep(scheduleItemsReturned);
                    setSchedulesOriginalInfo(originalInfoCopy);

                    let scheduleToTable = [];
                    dayToControl = "";
                    for (let indexSchedule = 0; indexSchedule < scheduleItemsReturned.length; indexSchedule++) {
                        if (indexSchedule === 0) {
                            dayToControl = dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD");
                        }

                        if (dayToControl !== dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD")) {
                            scheduleToTable.push({
                                _id: "empty" + indexSchedule,
                                schedule_date: "",
                                pacient_name: "",
                                health_plan_name: "",
                                hospital_name: "",
                                main_doctor_name: "",
                                diagnosis: "",
                                supplier_name: "",
                                doctors: [],
                                scale_assistant: false,
                                scale_resident: false
                            })

                            dayToControl = dayjs(scheduleItemsReturned[indexSchedule].schedule_date).format("YYYYMMDD");
                        }

                        scheduleToTable.push(scheduleItemsReturned[indexSchedule]);
                    }

                    setScheduleItems(scheduleToTable);
                }
            })
    }

    async function getAvailabilty() {
        const startDateFilter = dayjs().subtract(1, "month").toDate().toISOString();
        const endDateFilter = dayjs().add(1, "month").toDate().toISOString();

        await api.get(`/availabilities?startDate=${startDateFilter}&endDate=${endDateFilter}`)
            .then(response => {
                if (response.status === 200) {
                    availabilityList = response.data.availabilities;
                }
            })
    }

    async function getSummary() {
        const period = dayjs().format("YYYYMM");

        await api.get(`/doctor-summaries/info/${period}`)
            .then(response => {
                if (response.status === 200) {
                    const summaryReturned = response.data;
                    let newTotalSummary = {
                        last: {
                            forwarded: 0,
                            completed: 0
                        },
                        current: {
                            forwarded: 0,
                            completed: 0
                        },
                        accumulated: {
                            forwarded: 0,
                            completed: 0
                        }
                    };

                    for (let indexSummary = 0; indexSummary < summaryReturned.length; indexSummary++) {
                        newTotalSummary.last.forwarded += summaryReturned[indexSummary].last.forwarded;
                        newTotalSummary.last.completed += summaryReturned[indexSummary].last.completed;
                        newTotalSummary.current.forwarded += summaryReturned[indexSummary].current.forwarded;
                        newTotalSummary.current.completed += summaryReturned[indexSummary].current.completed;
                        newTotalSummary.accumulated.forwarded += summaryReturned[indexSummary].accumulated.forwarded;
                        newTotalSummary.accumulated.completed += summaryReturned[indexSummary].accumulated.completed;
                    }

                    const newDoctorsSummary = summaryReturned.map(summaryItem => {
                        const summaryPercent = (summaryItem.accumulated.completed / newTotalSummary.accumulated.completed) * 100;
                        return { ...summaryItem, percent: +summaryPercent.toFixed(2) }
                    })

                    setDoctorsSummary(newDoctorsSummary);
                }
            })
    }

    async function handleChangeWeek(type) {
        await handleSaveScale(false);

        setLoading(true);

        let newStartDate = startDateFilter;

        if (type === "B") {
            newStartDate = dayjs(startDateFilter).subtract(1, "week").toDate();
        } else if (type === "A") {
            newStartDate = dayjs(endDateFilter).add(1, "milisecond").toDate();
        } else {
            newStartDate = dayjs().startOf("week").toDate();
        }

        const newEndDate = dayjs(newStartDate).endOf("week").toDate();

        setStartDateFilter(newStartDate);
        setEndDateFilter(newEndDate);

        getSchedule(newStartDate, newEndDate);
        getAvailabilty(newStartDate, newEndDate);

        setLoading(false);
    }

    async function handleSaveScale(showMessages = true) {
        if (hotTableComponent) {
            const tableData = hotTableComponent.current.hotInstance.getData();

            if (showMessages) {
                setLoadingMessage("Salvando Escala...");
                setLoading(true);
            }

            for (let indexTable = 0; indexTable < tableData.length; indexTable++) {
                const scheduleItemTable = scheduleItems[indexTable];

                if (scheduleItemTable && !scheduleItemTable._id.includes("empty")) {
                    const newDoctorsList = scheduleItemTable.doctors.map(doctorItem => {
                        let doctorObservation = "";
                        if (doctorItem.doctor_id === "6568e21b524e21a7b766c514") {
                            doctorObservation = tableData[indexTable][7] ?? "";
                        } else if (doctorItem.doctor_id === "6568e21b524e21a7b766c51c") {
                            doctorObservation = tableData[indexTable][8] ?? "";
                        } else if (doctorItem.doctor_id === "6568e21b524e21a7b766c523") {
                            doctorObservation = tableData[indexTable][9] ?? "";
                        } else if (doctorItem.doctor_id === "6568e21b524e21a7b766c52a") {
                            doctorObservation = tableData[indexTable][10] ?? "";
                        } else if (doctorItem.doctor_id === "6568e21b524e21a7b766c532") {
                            doctorObservation = tableData[indexTable][11] ?? "";
                        } else if (doctorItem.doctor_id === "6568e21b524e21a7b766c53a") {
                            doctorObservation = tableData[indexTable][12] ?? "";
                        }

                        const observationToInsert = doctorObservation.replace(/[*]/g, "");

                        return { ...doctorItem, observation: observationToInsert.trim() };
                    });

                    const scheduleToUpdate = {
                        doctors: [...newDoctorsList],
                        scale_assistant: scheduleItemTable.scale_assistant,
                        scale_resident: scheduleItemTable.scale_resident,
                        scale_observation: tableData[indexTable][15] ?? ""
                    }

                    const scheduleOriginalItem = schedulesOriginalInfo.find(x => x._id === scheduleItemTable._id);
                    if (scheduleOriginalItem) {
                        if (scheduleOriginalItem.doctors) {
                            for (let indexDoctors = 0; indexDoctors < scheduleOriginalItem.doctors.length; indexDoctors++) {
                                await updateSummaryCount(scheduleOriginalItem.schedule_date, scheduleOriginalItem.doctors[indexDoctors].doctor_id, "SUB", "completed");
                            }
                        }
                    }

                    await api.put(`/schedules/${scheduleItemTable._id}`, scheduleToUpdate)
                        .then(async response => {
                            if (response.status === 200) {
                                for (let indexDoctors = 0; indexDoctors < scheduleItemTable.doctors.length; indexDoctors++) {
                                    await updateSummaryCount(scheduleItemTable.schedule_date, scheduleItemTable.doctors[indexDoctors].doctor_id, "ADD", "completed");
                                }
                            }
                        });
                }
            }

            if (showMessages) {
                swal("Escala gravada com sucesso!", { icon: "success" });

                getSchedule();
                getSummary();

                setLoading(false);
            }
        }
    }

    function markRenderer(TD, row, col, prop, value, cellProperties) {
        if (col > 6) {
            const scheduleRegister = scheduleItems[row];

            if (scheduleRegister?.doctors && scheduleRegister.doctors.length > 0) {
                if (col === 7) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c514") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                } else if (col === 8) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c51c") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                } else if (col === 9) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c523") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                } else if (col === 10) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c52a") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                } else if (col === 11) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c532") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                } else if (col === 12) {
                    if (scheduleRegister.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c53a") >= 0) {
                        TD.style.background = "#e0e0e0";
                    }
                }
            }

            if (col === 13) {
                if (scheduleRegister?.scale_assistant) {
                    TD.style.background = "#f9e6ca";
                }
            } else if (col === 14) {
                if (scheduleRegister?.scale_resident) {
                    TD.style.background = "#b5e4ec";
                }
            }
        }
    }

    function handleSelectCell(params) {
        if (hotTableComponent) {
            const [row, col] = hotTableComponent.current.hotInstance.getSelectedLast();

            if (col > 6 && col < 15) {
                const scheduleToMark = scheduleItems[row];

                if (!scheduleToMark._id.includes("empty")) {
                    let willMark = false;
                    let doctorIdToChange = "";

                    if (col === 7) {
                        doctorIdToChange = "6568e21b524e21a7b766c514";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c514") < 0) {
                            willMark = true;
                        }
                    } else if (col === 8) {
                        doctorIdToChange = "6568e21b524e21a7b766c51c";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c51c") < 0) {
                            willMark = true;
                        }
                    } else if (col === 9) {
                        doctorIdToChange = "6568e21b524e21a7b766c523";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c523") < 0) {
                            willMark = true;
                        }
                    } else if (col === 10) {
                        doctorIdToChange = "6568e21b524e21a7b766c52a";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c52a") < 0) {
                            willMark = true;
                        }
                    } else if (col === 11) {
                        doctorIdToChange = "6568e21b524e21a7b766c532";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c532") < 0) {
                            willMark = true;
                        }
                    } else if (col === 12) {
                        doctorIdToChange = "6568e21b524e21a7b766c53a";
                        if (scheduleToMark.doctors.findIndex(x => x.doctor_id === "6568e21b524e21a7b766c53a") < 0) {
                            willMark = true;
                        }
                    } else if (col === 13) {
                        doctorIdToChange = "ASSIST";
                        if (!scheduleToMark.scale_assistant) {
                            willMark = true;
                        }
                    } else if (col === 14) {
                        doctorIdToChange = "RESID";
                        if (!scheduleToMark.scale_resident) {
                            willMark = true;
                        }
                    }

                    let newScheduleItems = scheduleItems;

                    if (willMark) {
                        if (doctorIdToChange !== "ASSIST" && doctorIdToChange !== "RESID") {
                            hotTableComponent.current.hotInstance.setCellMeta(row, col, 'className', 'gray');

                            const doctorInfo = doctorsList.find(x => x._id === doctorIdToChange);
                            newScheduleItems[row].doctors.push({
                                doctor_id: doctorInfo._id,
                                doctor_name: doctorInfo.name,
                                doctor_short_name: doctorInfo.short_name
                            });
                        } else {
                            if (doctorIdToChange === "ASSIST") {
                                hotTableComponent.current.hotInstance.setCellMeta(row, col, 'className', 'yellow');
                                newScheduleItems[row].scale_assistant = true;
                            } else {
                                hotTableComponent.current.hotInstance.setCellMeta(row, col, 'className', 'blue');
                                newScheduleItems[row].scale_resident = true;
                            }
                        }
                    } else {
                        hotTableComponent.current.hotInstance.setCellMeta(row, col, 'className', '');

                        if (doctorIdToChange !== "ASSIST" && doctorIdToChange !== "RESID") {
                            const newDoctorsList = newScheduleItems[row].doctors.filter(x => x.doctor_id !== doctorIdToChange);
                            newScheduleItems[row].doctors = lodash.cloneDeep(newDoctorsList);
                        } else {
                            if (doctorIdToChange === "ASSIST") {
                                newScheduleItems[row].scale_assistant = false;
                            } else {
                                newScheduleItems[row].scale_resident = false;
                            }
                        }
                    }

                    setScheduleItems(newScheduleItems);

                    hotTableComponent.current.hotInstance.render();
                }
            }
        }
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Doctor" motherMenu="Records" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Escala</Card.Title>
                                <div>
                                    <Button
                                        variant="success btn-rounded"
                                        onClick={handleSaveScale}
                                        title="Salvar a escala"
                                        hidden={!hasAccess("scale", "update")}
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="app-fullcalendar" id="calendar">
                                    <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard">
                                        <div className="row">
                                            <div className="fc-header-toolbar fc-toolbar ">
                                                <div className="fc-toolbar-chunk">

                                                    <div className="fc-button-group">
                                                        <button
                                                            type="button"
                                                            title="Semana Anterior"
                                                            aria-pressed="false"
                                                            className="fc-prev-button fc-button fc-button-primary"
                                                            onClick={() => handleChangeWeek("B")}
                                                        >
                                                            <span className="fc-icon fc-icon-chevron-left">
                                                            </span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            title="Próxima Semana"
                                                            aria-pressed="false"
                                                            className="fc-next-button fc-button fc-button-primary"
                                                            onClick={() => handleChangeWeek("A")}
                                                        >
                                                            <span className="fc-icon fc-icon-chevron-right">
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        title="Esta semana"
                                                        aria-pressed="false"
                                                        className="fc-today-button fc-button fc-button-primary"
                                                        onClick={() => handleChangeWeek("T")}
                                                    >
                                                        Hoje
                                                    </button>
                                                </div>
                                                <div className="fc-toolbar-chunk">
                                                    <h2 className="fc-toolbar-title" id="fc-dom-5">{periodFilter}</h2>
                                                </div>
                                                <div className="fc-toolbar-chunk" />
                                            </div>
                                        </div>
                                    </div>
                                    <HotTable
                                        ref={hotTableComponent}
                                        data={newScheduleItems}
                                        height={450}
                                        colWidths={[100, 280, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 250]}
                                        colHeaders={[
                                            "Data",
                                            "Paciente",
                                            "Convênio",
                                            "Hospital",
                                            "MR",
                                            "Diagnóstico",
                                            "Empresa",
                                            "Mou",
                                            "Ale",
                                            "Ger",
                                            "Mau",
                                            "Gel",
                                            "Ele",
                                            "Adr",
                                            "Res",
                                            "OBS"
                                        ]}
                                        dropdownMenu={false}
                                        hiddenColumns={{
                                            indicators: true,
                                        }}
                                        afterSelectionEnd={handleSelectCell}
                                        fixedColumnsStart={2}
                                        contextMenu={false}
                                        columnSorting={false}
                                        multiColumnSorting={false}
                                        filters={false}
                                        rowHeaders={false}
                                        autoWrapCol={true}
                                        autoWrapRow={true}
                                        manualRowMove={false}
                                        afterRenderer={markRenderer}
                                        // afterSelection={(row, col, row2, col2) => {
                                        //     // this.getCell(row,col).css({ background: 'red' });
                                        //     console.log("selection:", [row, col, row2, col2]);
                                        //     // this.updateSettings({ color: "#e0e0e0" })
                                        // }}
                                        // cell={(row, col) => {
                                        //     console.log("cellProps")
                                        //     const cellProperties = {
                                        //         renderer: "cellRenderer"
                                        //     }

                                        //     return cellProperties;
                                        // }}
                                        licenseKey="non-commercial-and-evaluation"
                                    >
                                        <HotColumn data={1} readOnly />
                                        <HotColumn data={2} readOnly />
                                        <HotColumn data={3} readOnly />
                                        <HotColumn data={4} readOnly />
                                        <HotColumn data={5} readOnly />
                                        <HotColumn data={6} readOnly />
                                        <HotColumn data={7} readOnly />
                                        <HotColumn data={8} />
                                        <HotColumn data={9} />
                                        <HotColumn data={10} />
                                        <HotColumn data={11} />
                                        <HotColumn data={12} />
                                        <HotColumn data={13} />
                                        <HotColumn data={14} />
                                        <HotColumn data={15} />
                                        <HotColumn data={16} />
                                    </HotTable>
                                </div>

                                <br />

                                <h5>Resumo de Agendamentos</h5>
                                <Table responsive>
                                    <thead>
                                        <tr id="doctors">
                                            <th key="docResume"></th>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return <th key={`docRes${summary.doctor_id}`}>{summary.doctor_name}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="currentCompleted">
                                            <td key="currentHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Executados - Mês Atual</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`current${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.current.completed.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="lastCompleted">
                                            <td key="lastHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Executados - Mês Anterior</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`last${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.last.completed.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="accumulatedCompleted">
                                            <td key="accumHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Executados - Acumulado</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`accum${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.accumulated.completed.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="percentCompleted">
                                            <td key="percCompHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">% - Acumulado</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`perComp${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.percent.toLocaleString('pt-br')} %</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="divider1" style={{ backgroundColor: "#ffebcc" }}>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return <td key={`divider${summary.doctor_id}`} />;
                                                })
                                            }
                                            <td />
                                        </tr>
                                        <tr id="currentForwarded">
                                            <td key="currForwHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Ecaminhados - Mês Atual</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`currForw${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.current.forwarded.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="lastForwarded">
                                            <td key="lastForwHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Ecaminhados - Mês Anterior</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`lastForw${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.last.forwarded.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr id="accumulatedForwarded">
                                            <td key="accumForwHead">
                                                <div className="d-flex align-items-left">
                                                    <span className="w-space-no">Ecaminhados - Acumulado</span>
                                                </div>
                                            </td>
                                            {
                                                doctorsSummary.map((summary) => {
                                                    return (
                                                        <td key={`accumForw${summary.doctor_id}`}>
                                                            <div className="text-end">
                                                                <span className="w-space-no">{summary.accumulated.forwarded.toLocaleString('pt-br')}</span>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <ModalLoading
                visible={loading}
                message={loadingMessage}
                onClose={setLoading}
            />
        </>
    )
}

export default Scale;